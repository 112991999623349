<template>
    <div class="popup-login">

        <div class="popup-wrap" :class="{ 'popup-wrap--active' : isLoginOpen }">
            <!-- <img class="close" @click="closeLogin" src="../../assets/img/icon_close.svg" alt=""> -->
            <img class="logo" src="../../assets/img/logo_alt.svg" alt="">
            <p>Welcome to the Californa Wines Online Events Portal. Please enter your credentials to sign in below or email <a href="mailto:UKcontacts@californiawines.co.uk">UKcontacts@californiawines.co.uk</a> to request a user.</p>
            <!-- <img class="logo-sub" src="../../assets/img/logo-welcome-black.svg" alt=""> -->

            <div class="form">
              <input id="user-email" type="email" placeholder="EMAIL" autocomplete="on">
              <input id="user-pass" type="password" placeholder="PASSWORD">
              <p class="forgot-pass"><br></p>

              <button @click="login">LOG IN</button>

              <p class="info info--error">...</p>

              <div class="lds-ring lds-ring--login">
                <img class="loading-gif" src="../../assets/img/loadingwheel.gif" alt="">
              </div>

              <!-- <p class="info">IF YOU DON'T ALREADY HAVE AN ACCOUNT SIGN UP HERE TO GET FULL ACCESS TO THE EVENT</p>

              <button>SIGN UP</button> -->
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store/index.js'
import gql from 'graphql-tag'
import $ from 'jquery'

export default {
  name: 'PopupLogin',

  data() {
    return {
      email: "",
      password: ""
    }
  },

  methods: {
    closeLogin() {
      store.commit('toggleLogin')
    },
    login() {
      var email = $('#user-email').val();
      var pass  = $('#user-pass').val();
      var alert = $('.info--error');
      var wheel = $('.lds-ring');

      function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
      
      if (!validateEmail(email)) {
        alert.text("PLEASE ENTER A VALID EMAIL");
        alert.css('opacity', 1)
      } else if (pass == "") {
        alert.text("PLEASE ENTER A PASSWORD");
        alert.css('opacity', 1)
      } else {
        this.email = email 
        this.password = pass

        wheel.fadeIn(300);

        //Set UserID to local storage for re-authentication

        this.$apollo.mutate({
          client: 'craft',
          httpEndpoint: 'https://californiawines.co.uk/web/api',
          mutation: gql`mutation Authenticate ($email: String!, $password: String!) {
            authenticate (email: $email, password: $password) {
              jwt
              jwtExpiresAt
              refreshToken
              refreshTokenExpiresAt
              user {
                id
              }
            }
          }`,
          variables: {
            email: this.email,
            password: this.password
          }
        }).then((result) => {
          console.log("loggin in...");
          store.commit('setUserID', result.data.authenticate.user.id)
          store.commit('logInUser')
          store.commit('toggleLogin')

          if (store.getters.getuserDestination != String) {
            console.log(store.getters.getuserDestination);
            this.$router.push({path: store.getters.getuserDestination})
          } else {
            this.$router.push({name:'events'})
          }


          $('.popup-login').fadeOut();
        }).catch(() => {
          // Error
          wheel.fadeOut(100);
          alert.text("INCORRECT USERNAME OR PASSWORD");
          alert.css('opacity', 1)
        })
      }
    }
  },

  computed: {
    isLoginOpen() {
        return store.state.isLoginOpen
    }
  },

  mounted() {
    $('.wrapper').css("max-width", '100%');

    var scope = this;

    var email = document.getElementById("user-email");
    email.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        console.log("test");
        scope.login();
      }
    });

    var pass = document.getElementById("user-pass");
    pass.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        scope.login();
      }
    });
  },
}

</script>
