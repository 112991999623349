<template>
  <div v-if="this.$route.name != 'main'" id="app">
    <Header />
    <Sidebar />
    <PopupLogin v-if="isHolding"/>
    <ImageBanner v-if="isHome"/>
    <div v-if="isContact == false" class="wrapper">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
    <div v-else>
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
    <Footer />
  </div>
  <div v-else></div>
</template>

<script>
import store from '@/store/index.js'
import gql from 'graphql-tag'

import Header from '@/components/global/Header';
import Sidebar from '@/components/global/Sidebar';
import PopupLogin from '@/components/global/PopupLogin';
import ImageBanner from '@/components/ImageBanner'
import Footer from '@/components/global/Footer';

if (process.env.NODE_ENV === 'production') {
  if (location.protocol !== 'https:') {
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
  }
}

export default {
  components: {
    Header,
    Sidebar,
    PopupLogin,
    ImageBanner,
    Footer
  },

  computed: {
    isHolding() {
      return this.$route.name === 'landing'
    },
    isHome() {
      return this.$route.name === 'home'
    },
    isContact() {
      return this.$route.name === 'contact'
    }
  },

  mounted() {
    var role = store.getters.getUserRole;

    if (role == "none") {
      console.log("Fetching role...");

      var userID = store.getters.getUserID;
      var vue = this;

      checkRole(vue, userID, "eventAdmins");
    }
  },
}

function checkRole(vue, userID, group) {
  console.log("Checking " + group);

  vue.$apollo.query({
    client: 'craft',
    httpEndpoint: 'https://californiawines.co.uk/web/api',
    query: gql`query ($userID: [QueryArgument], $group: [QueryArgument]) {
        user(id: $userID, group: $group) {
          username
          fullName
            ... on User {
                profilePicture {
                    ... on user_Asset {
                    id
                    url
                    }
                }
            }
        }
    }`,

    fetchPolicy: 'network-only',

    variables: {
      userID: userID,
      group: group
    }
    
    }).then((data) => {
      console.log(data.data.user);

      if (data.data.user) {
        console.log("User role found!");

        if (group == "eventAdmins") {
          store.commit('setUserRole', 'moderator');
        } else {
          store.commit('setUserRole', 'participant');
        }

      } else {
        console.log("User role not found!");

        if (group == "eventAdmins") {
          checkRole(vue, userID, "eventUsers");
        } else {
          store.commit('setUserRole', 'viewer');
        }
        
      }
    }).catch((error) => {
      console.error(error);
  })
}
</script>