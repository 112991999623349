import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createProvider } from './vue-apollo'
// import VueSocketIOExt from 'vue-socket.io-extended';
// import { io } from 'socket.io-client';

// const socket = io('https://nameless-brushlands-81879.herokuapp.com:3000');

// Vue.use(VueSocketIOExt, socket, { store });

Vue.config.productionTip = false

new Vue({
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  store,
  router,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')

Vue.filter('striphtml', function (value) {
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});
