<template>
  <div class="footer" :class="{ 'footer--small' : isLanding || isHolding || isContact && isUserLoggedIn == false}">
    <div class="footer-wrap-landing" v-if="isLanding || isHolding && isUserLoggedIn == false">
      <div class="footer-nav">
          <router-link :to="{ name: 'terms-and-conditions' }">LEGAL</router-link>
          <p>COPYRIGHT © ESSENTIAL CALIFORNIA 2021</p>
          <router-link :to="{ name: 'terms-and-conditions' }">T&C PRIVACY</router-link>
      </div>

      <div class="footer-logo">
          <img src="../../assets/img/logo_alt_white.svg" alt="">
      </div>
    </div>

    <div class="footer-wrap-landing" v-if="isContact && isUserLoggedIn == false">
      <div class="footer-nav">
          <router-link :to="{ name: 'terms-and-conditions' }">LEGAL</router-link>
          <p>COPYRIGHT © ESSENTIAL CALIFORNIA 2021</p>
          <router-link :to="{ name: 'terms-and-conditions' }">T&C PRIVACY</router-link>
      </div>

      <div class="footer-logo">
          <img src="../../assets/img/logo_alt_white.svg" alt="">
      </div>
    </div>

    <div class="footer-wrap-main"  v-if="isLanding == false && isHolding == false && isUserLoggedIn">
      <div class="footer-logo">
          <img src="../../assets/img/logo_alt.svg" alt="">
      </div>

      <div class="footer-nav">
        <!-- <router-link :to="{ name: 'account' }">PROFILE</router-link>  -->
        <router-link :to="{ name: 'events' }">EVENTS</router-link>
        <router-link :to="{ name: 'contact' }">CONTACT</router-link>
        <router-link :to="{ name: 'terms-and-conditions' }">PRIVACY</router-link>
      </div>

      <div class="footer-misc">
        <div class="footer-socials">
          <a href="https://www.facebook.com/CaliforniaWines" target="_blank">
            <img src="../../assets/img/icon_facebook_light.svg" alt="Facebook">
          </a>
          <a href="https://www.instagram.com/California.wines.uk/" target="_blank">
            <img src="../../assets/img/icon_insta_light.svg" alt="Instagram">
          </a>
          <a href="https://twitter.com/CalifWinesUK" target="_blank">
            <img src="../../assets/img/icon_twitter_light.svg" alt="Twitter">
          </a>
        </div>

        <div class="footer-copyright">
          <p>Copyright © Essential california 2021</p>
        </div>
      </div>


    </div>
  </div>
</template>

<script>


export default {
  name: 'Footer',

  computed: {
    isLanding() {
      return false
    },

    isContact() {
      return this.$route.name === 'contact'
    },

    isHolding() {
      return this.$route.name == 'holding'
    },

    isUserLoggedIn() {
      return true
    }
  },
}
</script>