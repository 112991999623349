<template>
    <div class="image-banner">
        <img src="../assets/img/Homepage_Banner.jpg" alt="Banner">
        <img src="../assets/img/icon-dropdown.svg" class="hide-image" alt="Banner" @click="$event.target.parentElement.classList.toggle('hide')">
        <div class="banner-text">
            <h1>Welcome to <br> essential california </h1>
            <p>Thanks for joining us for the largest ever online tasting of California wines in the UK. We have a range of Masterclasses, AVA Videos, Special Winemaker videos and much more lined up for you so please check the Schedule below for what’s on.</p>
            <img class="banner-logo" src="../assets/img/logo_alt_white.svg" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name: 'ImageBanner'
}
</script>