<template>
  <div class="landing">
    <div class="landing-background">
      <img src="../assets/img/background.jpg" alt="">
    </div>

    <div class="landing-content">
      <img src="../assets/img/logo-welcome-white.svg" alt="">

      <p>COME BACK ON <br> 24TH OF MARCH TO LOGIN.</p>
    </div>
    <Cookies/>
  </div>
</template>
<script>
  import Cookies from '@/components/snippets/Cookies.vue'

  export default  {
    components: { 
        Cookies
    },
  }
</script>

