<template>
    <div class="cookie-banner">
        <div class="cookie-grid">
            <p>THIS WEBSITE USES COOKIES TO ENSURE YOU GET THE BEST EXPERIENCE ON OUR WEBSITE. 
                <router-link :to="{ name: 'terms-and-conditions' }">LEARN MORE HERE</router-link>
                </p>
            <button id="accept-cookie">ACCEPT</button> 
        </div>
    </div>
</template>
<script>
export default {
    name: 'Cookies'
}
</script>