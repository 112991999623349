import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import Landing from '../views/Landing.vue'
import Holding from '../views/Holding.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: {
      guest: true
    },
  },
  {
    path: '/events/test',
    name: 'test',
    component: () => import('../views/Event.vue'),
    meta: {
      requiresLogin: true
    },
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/Events.vue'),
    meta: {
      requiresLogin: true
    },
  },
  {
    path: '/events/live',
    name: 'events-live',
    component: () => import('../views/EventsLive.vue'),
    meta: {
      requiresLogin: true
    },
  },
  {
    path: '/events/upcoming',
    name: 'events-upcoming',
    component: () => import('../views/EventsFuture.vue'),
    meta: {
      requiresLogin: true
    },
  },
  {
    path: '/events/past',
    name: 'events-past',
    component: () => import('../views/EventsPast.vue'),
    meta: {
      requiresLogin: true
    },
  },
  {
    path: '/events/:slug?id=:eventId',
    name: 'event',
    component: () => import('../views/Event.vue'),
    meta: {
      requiresLogin: true
    },
  },
  {
    path: '/essential2021/holding',
    name: 'holding',
    component: Holding,
    meta: {
      guest: true
    },
  },
  {
    path: '/events/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/essential2021/home',
    name: 'home',
    meta: {
      requiresLogin: true
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/events/account',
    name: 'account',
    meta: {
      requiresLogin: true
    },
    component: () => import('../views/Account.vue')
  },
  {
    path: '/essential2021/calendar',
    name: 'calendar',
    component: () => import('../views/Calendar.vue')
  },
  {
    path: '/events/wines',
    name: 'wines',
    meta: {
      requiresLogin: true
    },
    component: () => import('../views/Products.vue')
  },
  {
    path: '/wines/:slug?id=:productId?',
    name: 'product',
    props: true,
    meta: {
      requiresLogin: true
    },
    component: () => import('../views/Product.vue')
  },
  {
    path: '/essential2021/importers',
    name: 'importers',
    meta: {
      requiresLogin: true
    },
    component: () => import('../views/Exhibitors.vue')
  },
  {
    path: '/essential2021/importer/:slug?id=:exhibitorId?', 
    name: 'exhibitor',
    props: true,
    meta: {
      requiresLogin: true
    },
    component: () => import('../views/Exhibitor.vue')
  },
  {
    path: '/essential2021/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('../views/Privacy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  var loggedIn = false;

  if (localStorage.getItem('userID')) {
    loggedIn = true;
  }

  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0;

  if (to.matched.some(record => record.meta.requiresLogin)) {
    if (to.name !== 'landing' && !loggedIn) {
      store.commit('setuserDestination', to.path);
      next({name:'landing'})
    } else {
      if (loggedIn && to.name == 'landing') {
        console.log("test 2");
        next({name:'events'})
      } else {
        console.log("test 4");
        next() 
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (to.name !== 'landing' && !loggedIn) {
      console.log("test 1 guest");
      next({name:'landing'})
    } else {
      if (loggedIn && to.name == 'landing') {
        console.log("test 2 guest");
        next({name:'events'})
      } else {
        console.log("test 3 guest");
        next() 
      }
    }
  } else {
    next() 
  }


})

export default router
